'use strict'
const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

function getPlaceholderValue(compProp, compData) {
    return _.get(compProp, ['placeholder', 'value']) || _.get(compData, ['placeholder', 'value'])
}

function fixPlaceholderStyle(pageJson, cache, mobileView, comp) {
    const styleProperties = _.get(pageJson.data.theme_data, [comp.styleId, 'style', 'properties'])
    if (!styleProperties) {
        return
    }
    const compProp = this.getComponentProperties(pageJson, comp)
    const compData = this.getComponentData(pageJson, comp)
    const isLegacyPlaceholderSelected = compData.value === getPlaceholderValue(compProp, compData)

    if (styleProperties.txt_placeholder) {
        return 
    }
    
    if (isLegacyPlaceholderSelected) {
        _.assign(pageJson.data.theme_data[comp.styleId].style.properties, {
            txt_placeholder: styleProperties.txt2
        })
    } else {
        _.assign(pageJson.data.theme_data[comp.styleId].style.properties, {
            txt_placeholder: styleProperties.txt
        })
    }
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.inputs.ComboBoxInput': fixPlaceholderStyle
}

/**
 * @exports utils/dataFixer/plugins/comboBoxPlaceholderStyleFixer
 * @type {{exec: exec}}
 */
module.exports = {exec: createHelperAndMigratePage(MIGRATION_MAP)}
