'use strict'

const _ = require('lodash')

/**
 * Reason for this data-fixer:
 * Both DS and viewer implement "isLandingPage()" by  isLandingPage || isMobileLandingPage
 *
 * This causes one problematic flow: {isLandingPage: true, isMobileLandingPage: false} - mobile also renders as landing page.
 *
 * This data fixer fixes 'isMobileLandingPage' for the following flow:
 * {true,false} => {true,true}
 */


/**
 * isCorruptedData function return if page dataItem has the problematic flow:
 * {isLandingPage: true, isMobileLandingPage: false}
 * @param dataItem - page dataItem
 * @returns {*|boolean}
 */
function isCorruptedData(dataItem) {
    return dataItem.isLandingPage && !dataItem.isMobileLandingPage
}

/**
 * Fixing for all pages data.
 * @param documentData
 */
function fixAllPagesDataIfNeeded(documentData) {
    _(documentData).filter({type: 'Page'}).forEach(dataItem => {
        if (_.get(dataItem.metaData, 'schemaVersion') === '1.0') {
            if (isCorruptedData(dataItem)) {
                fixLandingPageAndBumpSchemaVersion(dataItem)
            }
            _.set(dataItem.metaData, 'schemaVersion', '2.0')
        }
    })
}

function fixLandingPageAndBumpSchemaVersion(dataItem) {
    dataItem.isMobileLandingPage = true
}

module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        const docData = pageJson.data.document_data
        if (magicObject.isExperimentOpen('bv_fixLandingPageData') && docData) {
            fixAllPagesDataIfNeeded(docData)
        }
    }
}
