'use strict'

const _ = require('lodash')

function replaceLltWithLlf(res, value, key) {
    res[key.replace('llt_', 'llf_')] = value
    return res
}

function SUBSCRIBE_FORM_LINE_LAYOUT_STYLE_FIXER(styleData) {
    styleData.style.properties = _.reduce(styleData.style.properties, replaceLltWithLlf, {})
    styleData.style.propertiesSource = _.reduce(styleData.style.propertiesSource, replaceLltWithLlf, {})
}

const CORRUPT_STYLES_FIXER = {
    '*'(styleData) {
        if (styleData.id === 'THEME_DATA') {
            return
        }
        if (!styleData.style) {
            styleData.style = {
                properties: {},
                propertiesSource: {},
                groups: {}
            }
        }
    },
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat': SUBSCRIBE_FORM_LINE_LAYOUT_STYLE_FIXER,
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon': SUBSCRIBE_FORM_LINE_LAYOUT_STYLE_FIXER
}

function fixStyleParamsIfNeeded(style) {
    if (style.style) {
        _.forEach(style.style.properties, (paramValue, paramName, properties) => {
            if (typeof paramValue === 'string') {
                properties[paramName] = paramValue.replace(/(\d)x/g, '$1px')
            }
        })
    }
}

function createDefaultWixAppsVideoStyle() {
    const newStyle = {
        type: 'TopLevelStyle',
        id: 'v2',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        style: {
            properties: {
                brd: 'color_15',
                brw: '0px',
                rd: '0px',
                shd: '0 1px 4px rgba(0, 0, 0, 0.6);'
            },
            propertiesSource: {
                brd: 'theme',
                brw: 'value',
                rd: 'value',
                shd: 'value'
            },
            groups: {}
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.video.VideoDefault'
    }
    return newStyle
}

function addMissingStyleIfNeeded(themeData) {
    if (!themeData.v2) {
        const newStyle = createDefaultWixAppsVideoStyle()
        themeData.v2 = _.cloneDeep(themeData.vl) || newStyle
    }
}

function fixCorruptStyle(themeData) {
    _.forOwn(themeData, value => {
        CORRUPT_STYLES_FIXER['*'](value)
        if (CORRUPT_STYLES_FIXER[value.skin]) {
            CORRUPT_STYLES_FIXER[value.skin](value)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/stylesFixer
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson) {
        const themeData = pageJson.data && pageJson.data.theme_data
        if (pageJson.structure && pageJson.structure.type === 'Document') {
            addMissingStyleIfNeeded(themeData)
            fixCorruptStyle(themeData)
            _.forEach(themeData, style => {
                fixStyleParamsIfNeeded(style)
            })
        }
    }
}